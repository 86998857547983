import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const BlurBoxes = () => {
  return (
    <div>
      <Layout>
        <Head title="Blur Boxes" />
        <h2>Blur Boxes</h2>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565825599/striped%20blur%20boxes/1.jpg"
          alt="Benji Friedman blur box digital art, San Francisco Bay Area"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565825599/striped%20blur%20boxes/2.jpg"
          alt="Benji Friedman blur box digital art, San Francisco Bay Area"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565825599/striped%20blur%20boxes/3.jpg"
          alt="Benji Friedman blur box digital art, San Francisco Bay Area"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565825599/striped%20blur%20boxes/4.jpg"
          alt="Benji Friedman blur box digital art, San Francisco Bay Area"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565825599/striped%20blur%20boxes/5.jpg"
          alt="Benji Friedman blur box digital art, San Francisco Bay Area"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1565825599/striped%20blur%20boxes/6.jpg"
          alt="Benji Friedman blur box digital art, San Francisco Bay Area"
        />
        <Link to="/media">
          <h3>Media</h3>
        </Link>
      </Layout>
    </div>
  );
};

export default BlurBoxes;
